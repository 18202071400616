import ThemeColors from "../colors";

const palette = {
    primary: {
        main: ThemeColors.brandingRed,
        contrastText: ThemeColors.whiteColor,
    },
    secondary: {
        main: ThemeColors.greyColor,
        contrastText: ThemeColors.whiteColor,
    },
    error: {
        main: ThemeColors.redColor,
    },
    text: {
        primary: ThemeColors.blackColor,
        secondary: ThemeColors.greyColor,
        hint: ThemeColors.lightGreyColor,
    },
    background: {
        default: ThemeColors.whiteBackgroundColor,
        paper: ThemeColors.whiteBackgroundColor,
        backgroundField: ThemeColors.lighterGreyColor
    },
    action: {
        active: ThemeColors.greyColor,
        disabled: ThemeColors.greyColor,
    },
    foc: {
        main: ThemeColors.brandingRed,
        contrastText: ThemeColors.greyColor,
    },
    start: {
        main: "#777880",
        background:
            "#ffedd2",
        contrastText:
            "#ff9800",
    },
    create: {
        main: "#777880",
        background:
            "#f3ebf7",
        contrastText:
            "#8b6ca2",
    },
    cancel: {
        main: "#777880",
        background:
            "#fee3e3",
        contrastText:
            "#ef5350",
    },
    count: {
        main: "#777880",
        background:
            "#e2f5ff",
        contrastText:
            "#00a7ff",
    },
    complete: {
        main: "#777880",
        background:
            "#dbfde0",
        contrastText:
        ThemeColors.lemonGreenColor,
    },
}

export default palette