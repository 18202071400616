// Background colors
const whiteBackgroundColor = "#FFF";
const whiteColor = "#FFF";
const blackColor = "#000";
const overlayBlackColor = "rgba(0,0,0,0.5)";

// Text color
const greyColor = "#bbb";
const lightGreyColor = "#e0e0e0";
const lighterGreyColor = "#f2f2f2";
const overlayGrayColor = "rgba(242,242,242,0.5)";

// Action colors
const redColor = "#c30000cc";

const yellowColor = "#e1b412";
const lemonGreenColor = "#7AC16C"; // #247106

// Branding Colors
const brandingGreen = "#4b883f";
const brandingRed = "#801333";

const ThemeColors = {
  redColor,
  greyColor,
  whiteColor,
  blackColor,
  overlayBlackColor,
  lemonGreenColor,
  brandingRed,
  yellowColor,
  brandingGreen,
  lighterGreyColor,
  overlayGrayColor,
  lightGreyColor,
  whiteBackgroundColor,
};

export default ThemeColors;
