import {useSelector} from "react-redux";
import {redirect, useNavigate} from "react-router-dom";
import {MAIN_URL} from "../config/constants";
import {useEffect} from "react";

const GuestGuard = ({children}) => {
    const {isLoggedIn} = useSelector((state) => state?.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            navigate(`${MAIN_URL}`);
        }
    }, [isLoggedIn]);

    return <>{isLoggedIn ? <></> : children}</>;
};

export default GuestGuard;
