import { TextField, IconButton, InputAdornment } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ThemeColors from "config/colors";

const CustomTextField = React.memo(({
  id,
  title,
  value,
  error,
  onBlur,
  onFocus,
  autoComplete,
  inputRef,
  minDate,
  maxDate,
  touched,
  onChange,
  multiline,
  rows = 2,
  className,
  min = "0",
  placeholder,
  type = "text",
  size = "medium",
  fullWidth = true,
  required = false,
  margin = "normal",
  customHelperText,
  isDisabled = false,
  variant = "outlined",
  InputProps,
  ...newProps
}) => {
  const { t } = useTranslation();

  const classes = makeStyles((theme) => ({
    helperText: {
      fontSize: theme.typography.body2.fontSize,
      height: 0,
    },
  }))();

  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {type === "password" ? (
        <TextField
          {...newProps}
          touched={null}
          id={id}
          required={required}
          className={className}
          label={t(title)}
          size={size}
          placeholder={placeholder}
          error={touched && error != null ? true : false}
          name={id}
          autoComplete={autoComplete}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          disabled={isDisabled}
          min={min}
          margin={margin}
          fullWidth={fullWidth}
          helperText={touched && error ? error : customHelperText ?? null}
          FormHelperTextProps={{
            classes: {
              root: classes.helperText,
            },
          }}
          InputProps={{
            className: classes.input,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onMouseDown={handleMouseDownPassword}
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  size="large"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          type={showPassword ? "text" : "password"}
        />
      ) : type === "date" ? (
        <TextField
          multiline={multiline}
          rows={multiline ? rows : 1}
          {...newProps}
          touched={null}
          id={id}
          required={required}
          className={className}
          variant={variant}
          label={t(title)}
          size={size}
          placeholder={placeholder}
          error={touched && error != null ? true : false}
          name={id}
          onBlur={onBlur}
          onChange={onChange}
          type={type}
          value={value}
          disabled={isDisabled}
          min={min}
          margin={margin}
          fullWidth={fullWidth}
          helperText={touched && error ? error : customHelperText ?? null}
          FormHelperTextProps={{
            classes: {
              root: classes.helperText,
            },
          }}
          //this prop is only needed for  dates
          InputProps={
            type === "date"
              ? { inputProps: { min: minDate ?? "", max: maxDate ?? "" } }
              : {}
          }
          InputLabelProps={type === "date" ? { shrink: true } : {}}
        />
      ) : (
        <TextField
          {...newProps}
          id={id}
          size={size}
          touched={null}
          label={t(title)}
          variant={variant}
          required={required}
          multiline={multiline}
          className={className}
          rows={multiline ? rows : 1}
          placeholder={placeholder}
          name={id}
          onBlur={onBlur}
          onFocus={onFocus}
          autoComplete={autoComplete}
          inputRef={inputRef}
          onChange={onChange}
          type={type}
          value={value}
          disabled={isDisabled}
          min={min}
          margin={margin}
          fullWidth={fullWidth}
          error={touched && error != null ? true : false}
          helperText={touched && error ? error : customHelperText ?? null}
          FormHelperTextProps={{
            classes: {
              root: classes.helperText,
            },
          }}
          InputLabelProps={{ size: "100px" }}
          InputProps={{ className: classes.input, ...InputProps }}
        />
      )}
    </>
  );
});

export default CustomTextField;
