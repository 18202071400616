import permissions from "../config/permissions";

const initialValue = () => ({
  id: "",
  name: "",
});

const fromJSON = (data) => ({
  id: data?.permissionId,
  name: data?.permissionName,
});

const toMap = (data) => data?.id;

const getPermission = (p) => {
  return permissions.list.find((permission) => permission === p);
};
export const Permission = {
  toMap,
  fromJSON,
  initialValue,
  getPermission,
};
