import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    Box,
    ListSubheader,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {useState} from "react";
import ThemeColors from "config/colors";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {useNavigate} from "react-router-dom";

const DrawerEntry = ({ item, nested = false, childLvlTwo = false}) => {
    const classes = makeStyles((theme) => ({
        nested: {
            paddingLeft: theme.spacing(6),
        },
        childLvlTwo: {
            paddingLeft: theme.spacing(10),
        },

        iconMargin: {
            marginRight: "-18px",
            '&.Mui-selected': {
                color: ThemeColors.brandingRed
            }
        },
    }))();

    const [open, setOpen] = useState(false);
    const {t} = useTranslation();
    const navigate = useNavigate()

    const handleClick = (item) => {
        if (item?.type === "item" && !isTabSelected(item)) {
            navigate(item.url);
        } else if (item?.type === "collapse") {
            setOpen(!open);
        }
    };

    const isTabSelected = (item) => {
        return window?.location?.pathname === item?.url;
    };

    const userPermissions = useSelector(
        (state) => state?.auth?.user?.permissions ?? []
    );

    // check if the user got this permission only if the component requires a permission
    const showDrawerItem = () => {
        if (item?.permissions && item?.permissions?.length > 0) {
            let result = false;
            for (const i in item?.permissions) {
                for (const j in userPermissions) {
                    result |= item?.permissions[i] === userPermissions[j];
                }
            }
            return result;
        }

        return true;
    };

    if (showDrawerItem()) {
        return (
            <>
                {item?.type !== "title" && (
                    <ListItem
                        //TODO: Remove
                        button
                        sx={{textAlign: "inherit"}}
                        selected={isTabSelected(item)}
                        onClick={() => handleClick(item)}
                        className={`${nested ? classes.nested : ""} ${childLvlTwo ? classes.childLvlTwo : ""}`}
                    >
                        {item?.icon && (
                            typeof item?.icon === 'string' ?
                                <ListItemIcon
                                    className={classes.iconMargin}
                                >
                                    <Box
                                        sx={{
                                            backgroundImage: `url(${item?.icon})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            width: 30,
                                            height: 30,
                                            marginLeft: '-4px'
                                        }}/>
                                </ListItemIcon>
                                :
                                <ListItemIcon
                                    className={classes.iconMargin}
                                    sx={{color: ThemeColors.greyColor}}
                                >
                                    {item?.icon}
                                </ListItemIcon>
                        )}
                        <ListItemText
                            primary={t(item.title)}
                        />
                        {item?.type === "collapse" && (
                            <>{open ? <ExpandLess/> : <ExpandMore/>}</>
                        )}
                    </ListItem>
                )}

                {item?.type === "title" && (
                    <List
                        component="div"
                        subheader={
                            item?.title && (
                                <ListSubheader
                                    component="div"
                                    id="nested-list-subheader"
                                    style={{
                                        color: ThemeColors.blackColor,
                                        fontWeight: 700,
                                        position: "inherit",
                                    }}
                                >
                                    {t(item?.title)}
                                </ListSubheader>
                            )
                        }
                        disablePadding
                    >
                        {item?.children?.map((item, index) => (
                            <DrawerEntry key={index} item={item}/>
                        ))}
                    </List>
                )}

                {item?.type === "collapse" && (
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List
                            component="div"
                            subheader={
                                item?.subheader && (
                                    <ListSubheader component="div" id="nested-list-subheader">
                                        {item?.subheader}
                                    </ListSubheader>
                                )
                            }
                            disablePadding
                        >
                            {item?.children?.map((item, index) => (
                                <DrawerEntry key={index} item={item} nested childLvlTwo={item.childLvlTwo}/>
                            ))}
                        </List>
                    </Collapse>
                )}
            </>
        );
    } else {
        return <></>;
    }
};

export default DrawerEntry;
