//this is for localization
import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { create } from "jss";
import rtl from "jss-rtl";
import jssPreset from "@mui/styles/jssPreset";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "ar"],
    fallbackLng: "en",
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "assets/locales/{{lng}}/translation.json",
    },
    react: { useSuspense: false },
  });

export default i18n;

export const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
