import i18next from "i18next";
import cookies from "js-cookie";
import { useState, useEffect } from "react";
import languages from "localization/Languages";
import PublicIcon from "@mui/icons-material/Public";
import { IconButton, Menu, MenuItem, useTheme } from "@mui/material";
import ThemeColors from "../config/colors";

const LocalizationIcon = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const currLangCode = cookies.get("i18next") || "en";
  const currLang = languages.find((l) => l.code === currLangCode);
  useEffect(() => (document.body.dir = currLang.dir || "ltr"), [currLang.dir]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-haspopup="true"
        onClick={handleMenu}
        aria-controls="menu-appbar"
        size="large"
      >
        <PublicIcon sx={{color: ThemeColors.brandingRed}} />
      </IconButton>
      <Menu
        id="menu-appbar"
        className="mt-5"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        {languages.map(({ code, name, country_code }) => (
          <MenuItem
            key={country_code}
            selected={code === currLangCode}
            // disabled={code === currLangCode}
            onClick={() => {
              i18next.changeLanguage(code);
              setAnchorEl(false);
              window.location.reload(false);
              document.body.dir = i18next.dir();
              theme.direction = i18next.dir();
            }}
          >
            {name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LocalizationIcon;
