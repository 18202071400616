import {
  Box,
  List,
  Dialog,
  ListItem,
  Typography,
  IconButton,
  DialogTitle,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";
import CustomAvatar from "./CustomAvatar";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTextField from "./CustomTextField";
import CloseIcon from "@mui/icons-material/Close";
import { switchOrChooseTenant } from "../redux/slices/authSlice";
import { useNotificationAndBackdrop } from "hooks/useNotificationAndBackdrop";
import { GeneralService } from "services/GeneralService";
import { User } from "models/User";
import {useNavigate} from "react-router-dom";

const TenantSelector = ({
  onClose,
  open = false,
  loginTenants,
  isLoginSelector = false,
  tempToken = false,
}) => {
  const selectedTenant = useSelector(
    (state) => state?.auth?.user?.selectedTenant
  );

  const { t } = useTranslation();
  const [error, setError] = useState();
  const [tenants, setTenants] = useState();
  const [tenantSearch, setTenantSearch] = useState("");
  const storedTenants = useSelector((state) => state?.auth?.user?.tenants);
  const { displayNotification } = useNotificationAndBackdrop();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    if (loginTenants) {
      setTenants(loginTenants);
    } else {
      setTenants(storedTenants);
    }
  }, [loginTenants]);

  const handleListItemClick = async (theChosenTenant) => {
    try {
      // call switch tenant here to get new token and new refresh token
      const result = await GeneralService.userChooseOrSwitchTenant(
        theChosenTenant?.id,
        isLoginSelector,
        tempToken
      );

      if (!isTenantSelected(theChosenTenant) && !isLoginSelector) {
        dispatch(
          switchOrChooseTenant({
            isLoggedIn: true,
            token: result?.token,
            refreshToken: result?.refreshToken,
            user: User.choosingTenant(
              theChosenTenant,
              result?.fullName,
              storedTenants,
              result?.ownerId
            ),
          })
        );
        onClose();
        displayNotification({
          severity: "success",
          content: t("Site Switched Successfully"),
        });
        navigate("/");
      }
      onClose(theChosenTenant, result?.token, result?.refreshToken);
    } catch (e) {
      setError(e?.message);
    }
  };

  const isTenantSelected = (tenant) => {
    return tenant?.id === selectedTenant?.id;
  };

  const handleClose = () => {
    setError();
    onClose();
    setTenantSearch("");
  };
  const filterTenant = (tenant) => {
    const { id, name } = tenant;
    if (tenantSearch.trim().length === 0) {
      return tenant;
    } else if (id.includes(tenantSearch.trim())) {
      return tenant;
    } else if (name.toLowerCase().includes(tenantSearch.toLowerCase().trim())) {
      return tenant;
    }
  };
  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <Box className="d-flex align-items-center justify-content-between">
        <DialogTitle variant="div">
          <Typography variant="h6" className="font-weight-bold">
            {t("Choose Site")}
          </Typography>
        </DialogTitle>
        <IconButton onClick={handleClose} size="large">
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className="px-3">
        <CustomTextField
          title="Search by Name or GLN"
          value={tenantSearch}
          onChange={(e) => setTenantSearch(e.target.value)}
          size="small"
          margin="none"
        />
      </Box>
      <DialogContent className="p-0">
        <List>
          {tenants
            ?.filter((tenant) => filterTenant(tenant))
            .map((e, i) => (
              <ListItem
                selected={isTenantSelected(e)}
                key={i}
                button
                onClick={() => handleListItemClick(e)}
              >
                <ListItemAvatar>
                  <CustomAvatar />
                </ListItemAvatar>
                <ListItemText primary={e?.name} secondary={e?.id} />
              </ListItem>
            ))}
        </List>
        {error && (
          <Typography color="error" align="center" className="mb-4 form-text">
            {error}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TenantSelector;
