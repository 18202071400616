import { createSlice } from "@reduxjs/toolkit";

const backdropSlice = createSlice({
  name: "backdrop",
  initialState: false,
  reducers: {
    addBackdrop() {
      return true;
    },
    hideBackdrop() {
      return false;
    },
  },
});
export const { addBackdrop, hideBackdrop } = backdropSlice.actions;

export default backdropSlice.reducer;
