import { Permission } from "./Permission";
import { Tenant } from "./Tenant";

export const Role = {
  initialValue: () => initialValue(),
  initialDuplicateValue: () => initialDuplicateValue(),
  fromJSON: (data) => fromJSON(data),
  toMap: (data, multiTenant = false) => toMap(data, multiTenant),
  toDuplicateMap: (data, tenantId) => toDuplicateMap(data, tenantId),
};

const initialValue = () => ({
  id: "",
  name: "",
  tenant: "",
  description: "",
  permissions: [],
  categorizedPermissions: [],
});

const initialDuplicateValue = () => ({
  tenants: [],
});

const fromJSON = (data) => {
  let result = {
    id: data?.roleId,
    name: data?.roleName,
    tenant: Tenant.fromJSON(data),
    description: data?.description,
    permissions: data?.permissions?.map((e) => Permission.fromJSON(e)) ?? [],
    categorizedPermissions: data?.categorizedPermissions ?? [],
  };

  result["formattedPermissions"] =
    result?.permissions?.reduce(
      (str, p, i) =>
        str + p?.name + (i !== result.permissions?.length - 1 ? " - " : ""),
      ""
    ) ?? "";

  result["label"] = result?.name;
  return result;
};

const toMap = (data, multiTenant) => ({
  // multiTenant parameter when true allow TenantId accept array 'in the case of add role'
  Name: data?.name,
  TenantId: multiTenant ? data?.tenant.map((e) => e?.id) : data?.tenant?.id,
  Description: data?.description,
  PermissionsIds: []
    .concat(
      ...data?.categorizedPermissions.map((category) =>
        category.permissions.map((permission) =>
          permission?.isCheck ? permission.permissionId : ""
        )
      )
    )
    .filter((permission) => permission !== ""),
});

const toDuplicateMap = (data, tenantId) => ({
  Name: data?.name,
  TenantId: tenantId,
  Description: data?.description,
  PermissionsIds: [].concat(
    ...data?.categorizedPermissions.map((category) =>
      category.permissions.map((permission) => permission.permissionId)
    )
  ),
});
