import Cookies from "js-cookie";
import languages from "../../localization/Languages";
import {createTheme} from "@mui/material/styles";
import typography from "./typography";
import overrideComponents from "./overrideComponents";
import palette from "./palette";

const currLangCode = Cookies.get("i18next") || "en";
const currLang = languages.find((l) => l.code === currLangCode);

const direction = currLang.dir || "ltr";

const theme = createTheme({
    direction: direction,
    components: {
        ...overrideComponents(direction)
    },
    palette: {
        ...palette
    },
    typography: {
        ...typography,
    }
})

export default theme;
