import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTheSession } from "redux/slices/authSlice";

const SessionWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(setTheSession(auth));
  }, []);

  return <>{children}</>;
};

export default SessionWrapper;
