// Inbound Orders
const InboundView = "InboundView",
  InboundReject = "InboundReject",
  InboundAccept = "InboundAccept",
  InboundAutoAccept = "InboundAutoAccept",
  InboundCreateOrder = "InboundCreateOrder",
  InboundUpdateOrder = "InboundUpdateOrder",
  InboundReleaseToQuality = "InboundReleaseToQuality",
  CancelShipping = "CancelShipping";

// Outbound Orders
const OutboundView = "OutboundView",
  OutboundShipOut = "OutboundShipOut",
  OutboundCreateOrder = "OutboundCreateOrder",
  OutboundUpdateOrder = "OutboundUpdateOrder";

// File
const InboundFileView = "InboundFileView",
  InboundCreateInboundFile = "InboundCreateInboundFile";

// Partners
const PartnerView = "PartnerView",
  PartnerCreate = "PartnerCreate",
  PartnerImport = "PartnerImport";

// Products
const ProductView = "ProductView",
  ProductCreate = "ProductCreate",
  ProductImport = "ProductImport";

// Reports
const ViewProductQuantityReport = "ViewProductQuantityReport",
  ViewProductBySupplierReport = "ViewProductBySupplierReport",
  ViewProductAvailabilityReport = "ViewProductAvailabilityReport",
  DownloadEventDocument = "DownloadEventDocument";

// Dashboard
const ViewProductChartsReport = "ViewProductChartsReport",
  ViewDashboardStatistics = "ViewDashboardStatistics";

// User
const UserManagement = "UserManagement";

//Tenant Settings
const superadmin = "superadmin";

//Point of Sale
const POS_Sell = "POS_Sell";

//Verify status
const Verify_Status = "VerifyStatus";

//Decommission
const DecomissionView = "DecomissionView";

//Activate
const Activate = "ReActivate";

//Pack
const PackView = "PackView";
const AggregateWithExistSSCC = "AggregateWithExistSSCC";

//Unpack
const Disaggregation = "Disaggregation";

//Plant Master
const SO_Add = "SO_Add",
  SO_Push = "SO_Push",
  SO_Edit = "SO_Edit",
  SO_View = "SO_View",
  SO_Pause = "SO_Pause",
  SO_Cancel = "SO_Cancel",
  SO_Reject = "SO_Reject",
  SO_Delete = "SO_Delete",
  SO_Release = "SO_Release",
  SO_Approve = "SO_Approve",
  SO_UnAssign = "SO_UnAssign",
  SO_Complete = "SO_Complete",
  Machine_Add = "Machine_Add",
  Machine_Edit = "Machine_Edit",
  Machine_View = "Machine_View",
  SO_AssignLine = "SO_AssignLine",
  Machine_Delete = "Machine_Delete",
  SO_ProductView = "SO_ProductView",
  SO_DownloadSerials = "SO_DownloadSerials";

//Move Order
const MO_Add = "MO_Add",
  MO_Edit = "MO_Edit",
  MO_View = "MO_View",
  MO_Delete = "MO_Delete";

//Cycle Count
const CycleCount_View = "CycleCount_View",
  CycleCount_Create = "CycleCount_Create",
  CycleCount_Update = "CycleCount_Update",
  CycleCount_Start = "CycleCount_Start",
  CycleCount_Remove = "CycleCount_Remove",
  CycleCount_Cancel = "CycleCount_Cancel",
  CycleCount_Complete = "CycleCount_Complete",
  AdhocCycleCount_Create = "AdhocCycleCount_Create";
// Blind Receive
const BlindReceive_Create = "BlindReceive_Create";
// BlindReceive_View = "BlindReceive_View";

// Item Verification
const ItemVerification = "ItemVerification";
const permissions = {
  CancelShipping,
  MO_Add,
  SO_Add,
  SO_Push,
  SO_View,
  MO_View,
  SO_Edit,
  MO_Edit,
  SO_Pause,
  POS_Sell,
  Activate,
  PackView,
  AggregateWithExistSSCC,
  SO_Cancel,
  MO_Delete,
  SO_Reject,
  SO_Delete,
  SO_Release,
  SO_Approve,
  superadmin,
  SO_Complete,
  SO_UnAssign,
  Machine_Add,
  InboundView,
  ProductView,
  PartnerView,
  Machine_View,
  OutboundView,
  Machine_Edit,
  SO_AssignLine,
  InboundAccept,
  PartnerCreate,
  Verify_Status,
  PartnerImport,
  InboundReject,
  ProductCreate,
  ProductImport,
  SO_ProductView,
  Machine_Delete,
  Disaggregation,
  UserManagement,
  OutboundShipOut,
  InboundFileView,
  DecomissionView,
  InboundAutoAccept,
  InboundCreateOrder,
  SO_DownloadSerials,
  InboundUpdateOrder,
  OutboundCreateOrder,
  OutboundUpdateOrder,
  ViewDashboardStatistics,
  ViewProductChartsReport,
  InboundReleaseToQuality,
  InboundCreateInboundFile,
  ViewProductQuantityReport,
  ViewProductBySupplierReport,
  ViewProductAvailabilityReport,
  CycleCount_View,
  CycleCount_Create,
  CycleCount_Update,
  CycleCount_Start,
  CycleCount_Remove,
  CycleCount_Cancel,
  CycleCount_Complete,
  AdhocCycleCount_Create,
  BlindReceive_Create,
  // BlindReceive_View,
  ItemVerification,
  DownloadEventDocument,
};

export default { ...permissions, list: Object.values(permissions) };
