import ThemeColors from "../colors";

const overrideComponents = (direction) => ({
    MuiFormHelperText: {
        styleOverrides: {
            root: {
                textAlign: direction === 'rtl' ? 'right' : 'left'
            }
        },
    },
    MuiTextField: {
        styleOverrides: {
            root: {
                ".MuiInputBase-root.Mui-disabled": {
                    background: ThemeColors.lighterGreyColor,
                },
                label: {
                    transformOrigin: direction === 'rtl' ? 'right' : 'left',
                    right: '1.75rem',
                },
                fieldset: {
                    textAlign: direction === 'rtl' ? 'right' : 'left',
                    borderColor: ThemeColors.blackColor
                }
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                borderRadius: 4
            }
        }
    }
})

export default overrideComponents