import ThemeColors from "../colors";

const typography = {
    fontFamily: "Gilroy",
    h1: {
        fontSize: "40px !important",
        fontWeight: 700 + " !important",
        '@media (max-width:900px)': {
            fontSize: "30px !important",
        },
    },
    h2: {
        fontSize: "36px !important",
        fontWeight: 900 + " !important",
        color: ThemeColors.redColor,
        '@media (max-width:900px)': {
            fontSize: "30px !important",
        },
    },
    h3: {
        fontSize: "24px !important",
        fontWeight: 800 + " !important",
        '@media (max-width:900px)': {
            fontSize: "22px !important",
        },
    },
    h4: {
        fontSize: "22px !important",
        fontWeight: 700 + " !important",
        '@media (max-width:900px)': {
            fontSize: "20px !important",
        },
    },
    h5: {
        fontSize: "20px !important",
        fontWeight: 600 + " !important",
        '@media (max-width:900px)': {
            fontSize: "18px !important",
        },
    },
    h6: {
        fontSize: "18px !important",
        fontWeight: 500 + " !important",
        '@media (max-width:900px)': {
            fontSize: "16px !important",
        },
    },
    body1: {
        fontSize: "16px !important",
        fontWeight: 500 + " !important",
        '@media (max-width:900px)': {
            fontSize: "14px !important",
        },
    },
    body2: {
        fontSize: "14px !important",
        fontWeight: 400 + " !important",
        '@media (max-width:900px)': {
            fontSize: "12px !important",
        },
    },
    caption: {
        fontSize: "12px !important",
        fontWeight: 300 + " !important",
        '@media (max-width:900px)': {
            fontSize: "10px !important",
        },
    },
    subtitle1: {
        fontSize: "10px !important",
        fontWeight: 300 + " !important",
        textDecoration: "underline",
        '@media (max-width:900px)': {
            fontSize: "10px !important",
        },
    },
    subtitle2: {
        fontSize: "8px !important",
        fontWeight: 300 + " !important",
        '@media (max-width:900px)': {
            fontSize: "10px !important",
        },
    },
    button: {
        color: ThemeColors.brandingRed,
        borderRadius: 30,
        textTransform: "none",
        fontSize: 16,
        '@media (max-width:900px)': {
            fontSize: 14,
        },
    },
};

export default typography