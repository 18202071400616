import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import authReducer from "../slices/authSlice";
import notificationReducer from "../slices/notificationSlice";
import backdropReducer from "redux/slices/backdropSlice";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["notification", "backdrop"], // add slice name here to not maintain it in local storage
};
const rootReducer = combineReducers({
  auth: authReducer,
  notification: notificationReducer,
  backdrop: backdropReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});
export const persistor = persistStore(store);

// import reducers from "../reducers";
// import thunkMiddleware from "redux-thunk";
// import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";

// const key = process.env.REACT_APP_LOCAL_STORAGE_STATE_KEY;
// const saveToLocalStorage = (state) => {
//   try {
//     const serializedState = JSON.stringify(state);
//     localStorage.setItem(key, serializedState);
//   } catch (e) {}
// };

// const loadFromLocalStorage = () => {
//   try {
//     const state = localStorage.getItem(key);
//     return state == null ? undefined : JSON.parse(state);
//   } catch (e) {
//     return undefined;
//   }
// };

// const presistantState = loadFromLocalStorage();

// const store = createStore(
//   reducers,
//   presistantState,
//   composeWithDevTools(applyMiddleware(thunkMiddleware))
// );

// store.subscribe(() => {
//   const state = store.getState();
//   saveToLocalStorage(state);
// });

// export default store;
