import MiniDrawer from "./components/MiniDrawer";
import Box from "@mui/material/Box";
import * as React from "react";

const drawerWidth = 240;

const AdminLayout = ({children}) => {
    return (
        <Box sx={{display: 'flex'}}>
            <MiniDrawer
                history={history}
                children={children}
                drawerWidth={drawerWidth}
            />
            <Box component="main" sx={{flexGrow: 1, p: 3, width: `calc(100% - ${drawerWidth}px)`}}>
                <div style={{minHeight: "37px"}}/>
                {children}
            </Box>
        </Box>
    );
};

export default AdminLayout;
