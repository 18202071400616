import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import CustomImage from "components/CustomImage";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ThemeColors from "../../config/colors";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import * as React from "react";
import {styled, useTheme} from "@mui/material/styles";

const CustomDrawerHeader = ({handleDrawerClose}) => {
    const theme = useTheme();
    const clientLogo = useSelector((state) => state?.auth?.user?.logo);

    const DrawerHeader = styled('div')(({theme}) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    return (
        <DrawerHeader>
            <Link to="/home" className="d-flex align-items-center justify-content-center">
                <CustomImage
                    src={clientLogo}
                    alt="Logo"
                    style={{width: '60%'}}
                />
            </Link>
            <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon sx={{color: ThemeColors.brandingGreen}}/> :
                    <ChevronLeftIcon sx={{color: ThemeColors.brandingGreen}}/>}
            </IconButton>
        </DrawerHeader>
    );
};

export default CustomDrawerHeader;
