import { createSlice } from "@reduxjs/toolkit";
import { TOAST_TIMEOUT } from "../../config/constants";

const initialState = {
  open: false,
  severity: "error",
  content: "",
  duration: TOAST_TIMEOUT,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification(state, action) {
      const { severity, content, duration } = action.payload;
      state.open = true;
      state.severity = severity || "error";
      state.content = content;
      state.duration = duration || TOAST_TIMEOUT;
    },
    hideNotification(state) {
      state.open = false;
    },
  },
});

export const { addNotification, hideNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
