import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const AuthGuard = ({children}) => {
    const {isLoggedIn} = useSelector((state) => state?.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate(`/`);
        }
    }, [isLoggedIn]);

    return <>{!isLoggedIn ? <></> : children}</>;
};

export default AuthGuard;
