import MuiAlert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { Slide, Snackbar } from "@mui/material";
import { useNotificationAndBackdrop } from "hooks/useNotificationAndBackdrop";
import { useSelector } from "react-redux";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const Toaster = () => {
  const { t } = useTranslation();
  const { clearNotification } = useNotificationAndBackdrop();
  const notification = useSelector((state) => state.notification);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // check reason not working
    clearNotification();
  };

  return (
    <Snackbar
      open={notification.open}
      onClose={handleClose}
      autoHideDuration={notification.duration}
      TransitionComponent={TransitionUp}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <MuiAlert
        elevation={8}
        variant="filled"
        severity={notification.severity}
        onClose={handleClose}
      >
        {t(notification.content)}
      </MuiAlert>
    </Snackbar>
  );
};

export default Toaster;
