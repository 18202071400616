const initialValue = () => ({
  tenant: {},
});

const fromJSON = (data) => {
  let result = {
    gln: data?.gln,
    name: data?.name,
  };
  result["label"] = `${result.name}(${result.gln})`;
  return result;
};

const toMap = (data) =>
  data.reduce((obj, item) => ((obj[item.key] = item.value), obj), {});

const settingAddition = (data) => {
  let key = data.key;
  let result = {
    [key]: data.value,
  };
  return result;
};

const fromSetting = ({ value, ...data }) => ({
  ...data,
  value:
    typeof value == "boolean"
      ? value
        ? "true"
        : "false"
      : value === "true" || value === "false"
      ? value == "true"
        ? true
        : false
      : value,
  isUpdateable: data?.isUpdateable,
});
export const TenantsData = {
  toMap,
  fromJSON,
  fromSetting,
  initialValue,
  settingAddition,
};
