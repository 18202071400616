import axios from "axios";
import i18next from "i18next";
import { REQUEST_TIMEOUT, REQUEST_TIMEOUT_REPORTS } from "./constants";
import { trimObjectValues } from "helpers/functions";
import { logOut, updateUserData } from "redux/slices/authSlice";
import { store } from "redux/store";
import { GeneralService } from "services/GeneralService";

const WES_URL = process.env.REACT_APP_BASE_URL_API;
const Report_URL = process.env.REACT_APP_BASE_URL_REPORTS;
const Identity_URL = process.env.REACT_APP_BASE_URL_IDENTITY;

const axiosInstance = axios.create({
  baseURL: WES_URL,
  timeout: REQUEST_TIMEOUT,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const { url, data } = config;
    let trimmedStrings;

    if (typeof data === "object") {
      if (!(data instanceof FormData)) {
        trimmedStrings = trimObjectValues(data);
      } else {
        trimmedStrings = data;
      }
    } else {
      trimmedStrings = data;
    }

    const hasCultureParam = /\bculture=/i.test(url); // Check if 'culture=' parameter already exists

    if (hasCultureParam) {
      return config; // If 'culture=' parameter already exists, return the original config
    } else {
      const cultureParam = `culture=${i18next.language}`;
      const delimiter = url.includes("?") ? "&" : "?"; // Determine whether to use '?' or '&'
      const newUrl = `${url}${delimiter}${cultureParam}`;

      return {
        ...config,
        data: trimmedStrings,
        url: newUrl,
      };
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const refreshToken = store.getState().auth?.refreshToken;
    const user = store.getState().auth?.user;
    // set in the redux store the new accessToken & refreshToken data from success response and recall last API
    if (error?.response?.status === 401) {
      try {
        const res = await GeneralService.getNewAccessToken(refreshToken);

        if (res) {
          store.dispatch(
            updateUserData({
              isLoggedIn: true,
              token: res?.accessToken,
              refreshToken: res?.refreshToken,
              user,
            })
          );
          error.config.headers.Authorization = `Bearer ${res.accessToken}`;
          return axiosInstance(error.config);
        }
      } catch (error) {
        store.dispatch(logOut());
      }
    } else if (error?.response?.status === 403) {
      throw new Error(
        error.response.data?.message ||
          "You don't have permission to access this resource"
      );
    } else
      return Promise.reject(
        (error.response && error.response.data) ||
          "Unexpected Error, Please Contact Your Administrator."
      );
  }
);

//Axios for reports only
const axios_Reports = axios.create({
  baseURL: Report_URL,
  timeout: REQUEST_TIMEOUT_REPORTS,
});

axios_Reports.interceptors.request.use(
  (config) => {
    const { url } = config;
    const hasCultureParam = /\bculture=/i.test(url); // Check if 'culture=' parameter already exists
    if (hasCultureParam) {
      return config; // If 'culture=' parameter already exists, return the original config
    } else {
      const cultureParam = `culture=${i18next.language}`;
      const delimiter = url.includes("?") ? "&" : "?"; // Determine whether to use '?' or '&'
      const newUrl = `${url}${delimiter}${cultureParam}`;

      return {
        ...config,
        url: newUrl,
      };
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios_Reports.interceptors.response.use(
  (response) => response,
  async (error) => {
    const refreshToken = store.getState().auth?.refreshToken;
    const user = store.getState().auth?.user;
    // set in the redux store the new accessToken & refreshToken data from success response and recall last API
    if (error?.response?.status === 401) {
      try {
        const res = await GeneralService.getNewAccessToken(refreshToken);

        if (res) {
          store.dispatch(
            updateUserData({
              isLoggedIn: true,
              token: res?.accessToken,
              refreshToken: res?.refreshToken,
              user,
            })
          );
          error.config.headers.Authorization = `Bearer ${res.accessToken}`;
          return axios_Reports(error.config);
        }
      } catch (error) {
        store.dispatch(logOut());
      }
    } else if (error?.response?.status === 403) {
      throw new Error(
        error.response.data?.message ||
          "You don't have permission to access this resource"
      );
    } else
      return Promise.reject(
        (error.response && error.response.data) ||
          "Unexpected Error, Please Contact Your Administrator."
      );
  }
);

//Axios for Identity only
const axios_Identity = axios.create({
  baseURL: Identity_URL,
  timeout: REQUEST_TIMEOUT_REPORTS,
});

axios_Identity.interceptors.request.use(
  (config) => {
    const { url } = config;
    const isCultured = url.includes("?culture=");
    if (isCultured) {
      return config;
    } else {
      if (url.includes("?")) {
        return {
          ...config,
          url: url + "&culture=" + i18next.language,
        };
      } else {
        return { ...config, url: url + "?culture=" + i18next.language };
      }
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios_Identity.interceptors.response.use(
  (response) => response,
  async (error) => {
    const refreshToken = store.getState().auth?.refreshToken;
    const user = store.getState().auth?.user;
    // set in the redux store the new accessToken & refreshToken data from success response and recall last API
    if (error?.response?.status === 401) {
      try {
        const res = await GeneralService.getNewAccessToken(refreshToken);

        if (res) {
          store.dispatch(
            updateUserData({
              isLoggedIn: true,
              token: res?.accessToken,
              refreshToken: res?.refreshToken,
              user,
            })
          );
          error.config.headers.Authorization = `Bearer ${res.accessToken}`;
          return axios_Identity(error.config);
        }
      } catch (error) {
        store.dispatch(logOut());
      }
    } else if (error?.response?.status === 403) {
      throw new Error(
        error.response.data?.message ||
          "You don't have permission to access this resource"
      );
    } else
      return Promise.reject(
        (error.response && error.response.data) ||
          "Unexpected Error, Please Contact Your Administrator."
      );
  }
);

const simpleErrorHandling = (err, defaultText = "") => {
  if (err?.message) {
    throw err;
  } else {
    throw new Error(defaultText);
  }
};

export { axios_Reports, axios_Identity, simpleErrorHandling };

export default axiosInstance;
